import React from "react"

/**
 * The following forms are here to satisfy netlify's way of handling forms.
 * https://docs.netlify.com/forms/setup/#work-with-javascript-rendered-forms
 * @constructor
 */
const DummyForms = ({ departments }) => {
  return (
    <>
      {departments
        .filter(dep => dep.email.text.includes("@"))
        .map((department, i) => (
          <React.Fragment key={i}>
            <form
              name={department.name.text}
              data-netlify={true}
              netlify-honeypot="bot-field"
              hidden
            >
              <input type="text" name="fullName" />
              <input type="email" name="email" />
              <input type="number" name="phone" />
              <select name="department"></select>
              <textarea name="text"></textarea>
            </form>
          </React.Fragment>
        ))}
      <form
        name={"openOption"}
        data-netlify={true}
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="roomNumber" />
        <input type="text" name="weekNumber" />
        <input type="text" name="roomType" />
        <input type="text" name="priceRange" />
        <input type="text" name="fullName" />
        <input type="email" name="email" />
        <input type="number" name="phone" />
        <textarea name="text"></textarea>
      </form>
      <form
        name={"ownerRequest"}
        data-netlify={true}
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="roomNumber" />
        <input type="text" name="weekNumber" />
        <input type="text" name="roomType" />
        <input type="text" name="priceRange" />
        <input type="text" name="fullName" />
        <input type="email" name="email" />
        <input type="number" name="phone" />
        <textarea name="text"></textarea>
      </form>
    </>
  )
}

export default DummyForms
