import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useContactData = previewData => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicContactPage {
        _previewable
        uid
        data {
          title {
            html
          }
          tagline {
            text
          }
          page_text {
            text
          }
          body {
            ... on PrismicContactPageBodyDepartment {
              id
              primary {
                email {
                  text
                }
                fax {
                  text
                }
                name {
                  text
                }
                phone {
                  text
                }
                us_and_canada {
                  text
                }
              }
            }
            ... on PrismicContactPageBodyBook {
              id
              primary {
                link {
                  url
                  link_type
                }
                button_text
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({ staticData, previewData })
  const {
    uid,
    data: { title, tagline, page_text, body },
  } = mergedData.prismicContactPage
  const departments = body.map(slice => slice.primary)
  const bannerData = body.find(
    slice => slice.__typename === "PrismicContactPageBodyBook"
  )
  return {
    uid,
    title,
    tagline,
    page_text,
    departments,
    bannerData: bannerData ? bannerData.primary : undefined,
  }
}
