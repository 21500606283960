import React from "react"
import styled from "styled-components"
import { SectionSubTitle, SectionText } from "./atoms"

/**
 *
 * @param title
 * @param tagline
 * @param text
 * @param text_left
 * @param text_right
 * @param navItems
 * @constructor
 */
const PageHeader = ({
  title,
  tagline = undefined,
  text = undefined,
  text_left = undefined,
  text_right = undefined,
  navItems = undefined,
}) => {
  const scroll = e => {
    const id = e.target.ariaLabel
    const target = document.querySelector("#" + id)
    const targetPosition =
      target.getBoundingClientRect().top + window.pageYOffset
    const headerHeight = 84
    const spacing = 24
    const scrollTo = targetPosition - headerHeight - spacing
    window.scrollTo({
      top: scrollTo,
      behavior: "smooth",
    })
  }
  return (
    <Container>
      <HeadingContainer>
        <div dangerouslySetInnerHTML={{ __html: title.html }} />
        {tagline ? <SectionSubTitle>{tagline.text}</SectionSubTitle> : null}
      </HeadingContainer>
      {text ? <SectionText width="700px">{text.text}</SectionText> : null}
      {text_left && text_right ? (
        <TextContainer>
          <TextLeft>{text_left.text}</TextLeft>
          <TextRight>{text_right.text}</TextRight>
        </TextContainer>
      ) : null}
      {navItems ? (
        <Navigation>
          {navItems.map((roomType, i) => (
            <Anchor key={i} aria-label={roomType} onClick={scroll}>
              {roomType}
            </Anchor>
          ))}
        </Navigation>
      ) : null}
    </Container>
  )
}

export default PageHeader

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 40px 0;
`

const HeadingContainer = styled.div`
  padding-bottom: 0;
  h1 {
    font-family: "Canela Light", sans-serif;
    font-size: 4rem;
    margin: 1rem 0 1rem 0;
    text-align: center;
    color: var(--primary-text);
  }
`
const Navigation = styled.div`
  display: flex;
  justify-content: center;
  margin: 148px 0 0 0;
`

const Anchor = styled.p`
  text-decoration: none;
  font-family: "Moderat Medium", sans-serif;
  margin: 0 32px;
  padding: 4px;
  color: var(--primary-text);
  text-transform: uppercase;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 1px 0 var(--primary-text);
  }
  @media (max-width: 620px) {
    margin: 0 8px;
  }
  @media (max-width: 450px) {
    margin: 0 4px;
    font-size: 14px;
  }
`
const TextContainer = styled.div`
  grid-gap: 40px;
  color: var(--primary-text);
  width: 70%;
  margin: 0 auto;
  line-height: 1.6rem;
  text-align: center;
`

const TextLeft = styled.p``
const TextRight = styled.p``
