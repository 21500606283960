import React from "react"
import styled from "styled-components"

import ClientLink from "../atoms/clientLink"

const Department = ({ name, email, phone, fax, us_and_canada }) => {
  return (
    <Container>
      <Name>{name.text}</Name>
      {!!email.text ? (
        <Text>
          E-mail: <ClientLink type="mail" value={email.text} />
        </Text>
      ) : null}
      {!!phone.text ? (
        <Text>
          Phone: <ClientLink type="phone" value={phone.text} />
        </Text>
      ) : null}
      {!!fax.text ? (
        <Text>
          Fax: <ClientLink type="phone" value={fax.text} />
        </Text>
      ) : null}
      {!!us_and_canada.text ? (
        <Text>
          USA & Canada Toll Free:{" "}
          <ClientLink type="phone" value={us_and_canada.text} />
        </Text>
      ) : null}
    </Container>
  )
}

export default Department

const Container = styled.div`
  width: 350px;
  background: rgba(0, 0, 0, 0.1);
  margin: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  align-items: center;
  text-align: center;
  justify-content: center;
`

const Name = styled.p`
  font-weight: bold;
  padding: 4px;
`
const Text = styled.div`
  padding: 4px;
  display: flex;
  align-items: baseline;
  a {
    margin-left: 8px;
  }
`
