import React from "react"
import styled from "styled-components"
import Department from "./department"
import DummyForms from "../molecules/dummyForms"

const Departments = ({ departments }) => {
  return (
    <>
      <Container>
        {departments.map((department, i) => (
          <Department key={i} {...department} />
        ))}
      </Container>
      <DummyForms departments={departments} />
    </>
  )
}

export default Departments

const Container = styled.div`
  padding: 40px 0;
  display: flex;
  max-width: 95%;
  margin: 0 auto;
  //grid-template-columns: repeat(auto-fill, minmax(300px, 350px));
  grid-gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
`
